* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.terms {
    height: auto;
    width: 80%;
    background-color: rgb(34, 34, 55);
    position: relative;
    padding: 20px;
    margin-left: 120px; /* Add margin from the left */
    color: white;
    border-radius: 50px;
    padding-right: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
    
}

.information h2 {
    margin-top: 5px;
    display: flex;
    justify-content:flex-start;
    margin-top: 20px;
    margin-left: 20px;
    padding-top: 50px;
    padding-left: 40px;
}

.information p {
    text-align: left;
    margin-left: 60px;
    padding-left: 20px;
    color: white;
    display: flex;
}

.terms-content {
    margin-top: 40px;
}

.terms-content h3 {
    text-align: left;
    margin-top: 20px;
    margin-left: 20px;
    padding-top: 50px;
    padding-left: 40px;
}

.terms-content p {
    text-align: left;
    margin-left: 60px;
    padding-left: 20px;
    color: white;
    display: flex;
}

.terms-content ul {
    list-style-type: none;
    padding-left: 20px;
}

.terms-content ul li {
    margin-top: 10px;
    font-size: 18px;
    color: white;
    text-align: left;
    margin-left: 80px;
}

.terms-content ul li::before {
    content: "\2022"; /* Unicode character for bullet (•) */
    color: white; /* Color of the bullet */
    font-size: 20px; /* Size of the bullet */
    display: inline-block;
    width: 1em; /* Adjust as needed */
    margin-left: -1em; /* Adjust as needed */
}

.terms-content h3, .terms-content h4 {
    text-align: left;
    margin-top: 20px;
    margin-left: 20px;
    padding-top: 50px;
    padding-left: 40px;
}

.terms-content h4 {
    margin-top: 10px;
}

.terms-content p {
    text-align: left;
    margin-left: 60px;
    padding-left: 20px;
    color: white;
    display: flex;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.last{
    margin-right: 260px;
}


/* Responsive media queries */

@media screen and (max-width:992px){
    .terms{
        
        width: 70%;
  
    }
}

@media screen and (max-width:768px){
    .terms{
        
        width: 60%;
  
    }
    .information h2,.terms-content h3{
        font-size: 18px;
    }

    .information p, .terms-content p{
        font-size: 10px;
    }

    .terms-content ul li {
        font-size: 10px;
       }
   
       .terms-content ul li::before {
           font-size: 10px;
       }

       .last{
        margin-right: 2px;
       }

    
}

@media screen and (max-width:576px){
    .terms{
        
        width: 58%;
  
    }
}

@media screen and (max-width:480px){
    .terms{
        
        width: 80%;
        margin-left: 40px;
  
    }
}