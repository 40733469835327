*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}
body{
    background: #0f1b21;
    color: white;
}
.error-message {
    color: white;
  }
.ai-image{
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
    gap: 30px;
}

.header{
    font-size: 45px;
    font-weight: 500;
    padding-bottom: 10px;
    color: white;
}
.header span{
    color: #DE1B89;
    font-weight: 600;
}
.img-loading{
    display: flex;
    flex-direction: column;
}

.img-loading img{
    width: 512px;
}

.search-box{
    display: flex;
    width: 750px;
    height: 65px;
    justify-content: space-around;
    align-items: center;
    border-radius: 50px;
    background: #1F3540;

}

.search-input{
    width: 600px;
    height: 50px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 18px;
    color: white;
    padding-left: 35px;
    margin-right: 35px;
}

.search-input::placeholder{
    color: #999;
}

.generate-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
   height: 65px;
   font-size: 20px;
   border-radius: 50px;
   background: #DE1B89;
   cursor: pointer;
   color: white;
   font-weight: bold;
}

.loader{
    transform: scale(0.4); /* Scales the GIF to 50% of its original size */
    transform-origin: center 25% ;
    
}






/* .loader{
    color:  black;
}
.loading-bar{
    width: 1000px;
    height: 8px;
    background: #DE1B89;
}

.loading-bar-full{
    width: 512px;
    height: 8px;
    background: #DE1B89;
    transition: 15s;
} */


/* Responsive media queries */

@media screen and (max-width:992px){

    .search-box{
       
        width: 700px;
        height: 65px;

    
    }

    .generate-btn{
          width: 280px;

}

}

@media screen and (max-width:768px){

    .search-box{
       
        width: 480px;
        height: 50px;

    
    }

    .generate-btn{
          width: 250px;
          height: 50px;

}


}


@media screen and (max-width:576px){
    
    
    .search-box{
       
        width: 450px;
        height: 50px;

    
    }

    .generate-btn{
          width: 250px;
          height: 50px;

}

.img-loading{
    display: flex;
    flex-direction: column;
}

.img-loading img{
    width: 400px;
}
}

@media screen and (max-width:480px){
    .header{
        font-size: 30px;
        font-weight: 500;
        padding-bottom: 10px;
        color: white;
    }

        
    .search-box{
       
        width: 350px;
        height: 50px;

    
    }

    .generate-btn{
          width: 320px;
          height: 40px;
          font-size: 14px;

}
.img-loading img{
    width: 350px;
    
}
.search-input{
 
    font-size: 12px;
    
   
}


 
}





