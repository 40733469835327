*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}
.aboutus{
    margin-top: 40px;
    position: relative;
    height: auto;
    background-color: rgb(34, 34, 55);
    width: 80%;
    margin-left: 120px;
    border-radius: 50px;
    padding-right: 40px;
    

}

.welcome h2{
    margin-top: 5px;
    font-weight: bold;
    font-size: 50px;
    justify-content:flex-start;
    display: flex;
    margin-left: 20px;
    padding-top: 50px;
    padding-left: 40px;

}

.welcome p{
    justify-content:flex-start;
    display: flex;
    color: white;
    text-align: left;
    margin-left: 60px;
    padding-left: 20px;
    
    
}

h4{

    margin-top: 100px;
    justify-content:flex-start;
    display: flex;
    margin-left: 10px;
    color: white;
    font-weight: bold;
    font-size: 30px;
    padding-left: 40px;
}

.who-what-do p{
    text-align: left;
    color: white;
    margin-bottom: 40px;
    justify-content:flex-start;
    display: flex;
    margin-left: 60px;
    padding-left: 20px;
    padding-bottom: 20px;
}

/* Responsive media queries */

@media screen and (max-width:992px){
    .aboutus{
        width: 70%;
    }
}

@media screen and (max-width:768px){
    .aboutus{
        width: 60%;
    }

    .welcome h2,.who-what-do h2,h4{
        font-size: 18px;
    }

    .welcome p, .who-what-do p{
        font-size: 10px;
    }

}

@media screen and (max-width:576px){
    .aboutus{
        
        width: 58%;
  
    }
}
@media screen and (max-width:480px){
    .aboutus{
        
        width: 80%;
        margin-left: 40px;
  
    }
}