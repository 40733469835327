*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}


.headings h2{
    align-items: center;
    justify-content: center;
    position: relative;
    display: flex;
    font-weight: bold;
    font-size: 50px;
}
.headings p {
    margin-top: 80px;
    font-size: 25px;
    color: white;
}



.details p{
color: white;
}
.link{
    margin: 50px auto;
    width: 300px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    transition: transform 0.3s ease-in-out;
    border-radius: 50px;
   
    
    
}  

.link a{
    color: black;
    text-decoration: none;
    font-weight: bold;
    
    
  
} 

.link:hover{
    transform: scale(1.06);
    
}

/* Responsive media queries */

@media screen and (max-width:992px){

}

@media screen and (max-width:768px){


}

@media screen and (max-width:576px){
   
}
@media screen and (max-width:480px){

}








