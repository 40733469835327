*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

/* navbar */
.main-nav{
    background: #0f1b21;
    display: flex;
    justify-content: end;
    height: 80px;
    padding: 0 20px;
    
}

.menu-link{
    flex:2;
    padding-right: 40px;
    padding-top: 8px;
    display: flex;
    justify-content: end;
}

.main-nav li{
    
    float: left;
    list-style: none;
    margin: 20px 30px;
    
    
    
}
.main-nav ul{
    overflow: auto;
    display: flex;
    justify-content: end;
    
    
    
    
    
    
    
}

.menu-link li a{
    position: relative;
    padding: 3px 3px;
    text-decoration: none;
    color:  white;
    font-size: 1.1rem;
    

}
.menu-link li a:hover{
   
}
   


.logo{
    flex:1;
    display: flex;
}

.logo a{
    text-decoration: none;
    color: white;
    display: flex;
    font-size: 2.5rem;
    padding-top: 6px;
    padding-left: 40px;
    font-weight: bold;

}


/* @media (max-width: 768px) {
    .main-nav {
        flex-direction: column;
        align-items: start;
        padding: 10px;
    }

    .logo {
        justify-content: center;
        width: 100%;
        padding-left: 0;
    }

    .menu-link {
        flex-direction: column;
        align-items: start;
        width: 100%;
        padding-right: 0;
    }

    .main-nav ul {
        flex-direction: column;
        width: 100%;
    }

    .main-nav li {
        margin: 10px 0;
    }
} */

/* Responsive media queries */

@media screen and (max-width:992px){
    .logo a{
        padding-left: 3px;
        font-size: 2.4rem;
    }

    .menu-link li a{
        
        padding: 1px 1px;
        text-decoration: none;
        color:  white;
        font-size: 1rem;
        
        
    
    }
}


@media screen and (max-width:768px){
    .logo a{
        margin-top: 8px;
        font-size: 2rem;
    }

    .menu-link li a{
        
        padding: 4px 4px;
        text-decoration: none;
        color:  white;
        font-size: 1rem;

    }

    .main-nav li{
    
        float: left;
        list-style: none;
        margin: 20px 6px;

    }
}


@media screen and (max-width:576px){
    .logo a{
        margin-top: 15px;
        font-size: 1.5rem;
    }

    .menu-link li a{
        
        padding: 8px 8px;
        text-decoration: none;
        color:  white;
        font-size: 1rem;
        
    }

    .main-nav li{
    
        float: left;
        list-style: none;
        margin: 20px 1px;

    }

    .menu-link{
        flex:2;
        padding-right:5px;
        padding-top: 8px;
        display: flex;
        justify-content: end;
    }
    
}

@media screen and (max-width:480px){

    .logo a{
        margin-top: 15px;
        font-size: 1rem;
        justify-content: center;
        align-self: center;
        

        
    }
    .menu-link li a{
        
        padding: 8px 8px;
        text-decoration: none;
        color:  white;
        font-size: 1rem;
        visibility: hidden;
        display: none;
        
    }
}

