*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}
body{
   
}

.container{
    display: flex;
    background-color: #0f1b21;
    height: 600px;
    margin-top: 2px;
    justify-content: center;
    overflow: auto;
    
    
    
    
}

.container .heading{
    color: white;
    margin-top: 20px;
    padding-top: 5px;
   position: absolute;
    
    
    
}
.container .heading h1{
    padding-top: 5px;
    font-weight: bold;
    font-size: 60px
}

.container h2{
    display: flex;
    color: white;
    font-size: 40px;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;

} 
p{
 position: relative;
 display: flex;
 font-size: 20px;
 margin-top: 20px;
 justify-content: center;
 align-items: center;
 text-align: center;
 color: #afafaf;

}

.ge{
    color:#DE1B89;
}

.to{
    color: #66FCF1;
}

.container .image{
    
    background-color: #0f1b21;
    align-items: center;
    justify-content: center;
    height: 50%;
    margin-left: 15px;
    display: flex;
    margin-top: 260px;
   
    
    
}

.container .gif{
    
    
    align-items: center;
    justify-content: center;
    height: 50%;
    background-color: #0f1b21;
    display: flex;
    margin-top: 260px;
}

.container .image,.container .gif{
    margin-right: 20px;
    flex: 1;

}

.image h1{
    
    color: #DE1B89;
    font-weight: bold;
    margin-bottom: 180px;
    
}

.gif h1{
    color: #66FCF1;
    font-weight: bold;
    margin-bottom: 180px;
}

.btns{
    position: absolute;
    color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 160px;
    padding-right: 85px;
    padding-left: 70px;
    height: 60px;
    width: 400px;
    background: #DE1B89;
    border-radius: 50px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    font-size: 20px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    
    
}  

.btns:hover{
    transform: scale(1.1, 1.1); /* Scaling effect on hover */
    box-shadow: 0 0 30px #DE1B89; /* Glow effect on hover */
}




.btnss{
    position: absolute;
    color: black;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 160px;
    padding-right: 85px;
    padding-left: 70px;
    height: 60px;
    width: 400px;
    background: #66FCF1;
    border-radius: 50px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    font-size: 20px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.btnss:hover{
    transform: scale(1.1, 1.1); /* Scaling effect on hover */
    box-shadow: 0 0 30px #66FCF1; /* Glow effect on hover */
}

.info{
    margin-top: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  
  }
  
  .i-g{
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 80px;
      margin-top: 100px
      
  }
  .i-g h2{
      font-weight: bold;
  }
  
  .i-g p{
       margin-top: 8px;
      text-align: left;
      font-size: 18px;
      color: rgb(210, 209, 209);
  
  }
  
  .image-pic{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 350px;
      height: auto;
      margin-right: 150px;
      
  }

  .image-pic img{
   width: 80%;
   border-radius: 50px;
   height: 600px;
   margin-bottom: 50px;
   
  }
  
  .i-g-btn {
      margin-top: 10px;
      padding: 8px 25px; 
      font-size: 18px;
      border-radius: 50px; 
      
    }






    .info2 {
        margin-top: 60px;
        display: flex;
        justify-content: flex-end;
        align-items: center; 
        margin-bottom: 40px;
      }
      
 
      
      .g-g {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-right: 80px;
      }
      
      .g-g h2{
        font-weight: bold;
        margin-right: 456px;
      }

      .g-g p{
        margin-top: 8px;
       text-align: left;
       font-size: 18px;
       color: rgb(210, 209, 209);
   
   }

   .gif-pic{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 350px;
    height: auto;
    margin-left: 74px;
}
  
.g-g-btn {
    margin-top: 10px;
    padding: 8px 65px; 
    font-size: 18px;
    border-radius: 50px; 
    margin-right: 396px;

  }









/* Responsive media queries */

@media screen and (max-width:992px){

    .container h2{
        font-size: 30px;
    }
    .container .heading h1{
        font-size: 50px
    }

    .btns{
        width: 300px;
    }
    .btnss{
        width: 300px;
    }
    .image h1{
        font-size: 34px;
    }

    .gif h1{
        font-size: 34px;
    }

    .image-pic img{
        width: 120%;
        height: 90%;
        border-radius: 50px;
        margin-top: 80px;
        margin-right: -100px;
       }

       .i-g-btn{
        margin-bottom: 40px;
       }
       .info{
        margin-top: 1px;
       }
    .info p{
        
        font-size: 12px;
    }
    .info h2{
        font-size: 16px;
    }
     
    
  
}


@media screen and (max-width:768px){


    .container h2{
        font-size: 20px;
    }
    .container .heading h1{
        font-size: 40px
    }
    .btns{
        width: 200px;
        height: 50px ;
        margin-bottom: 50px;
    }
    .btnss{
        width: 200px;
        height: 50px ;
        margin-bottom: 50px;
        padding: 20px;
    }
    .image h1{
        font-size: 20px;
        align-items: center;
    }

    .gif h1{
        font-size: 24px;
    }
    p{
        font-size: 16px;
    }

    .image-pic img{
        width: 120%;
        height: auto;
        border-radius: 50px;
        margin-top: 75px;
        margin-right: -90px;
        
       }
       
       .i-g-btn{
        margin-bottom: 40px;
        height: 32px;
        margin-right: 20px;
        font-size: 10px;
       }
       .info{
        margin-top: 1px;
       }
    .info p{
        
        font-size: 10px;
    }
    .info h2{
        font-size: 20px;
    }

}


@media screen and (max-width:576px){

    .container h2{
        font-size: 15px;
    }
    .container .heading h1{
        font-size: 30px;
        margin-left: 25px;
    }
    .btns{
        width: 200px;
        height: 45px ;
        margin-bottom: 60px;
    }
    .btnss{
        width: 200px;
        height: 45px ;
        margin-bottom: 60px;
    }
    .image h1{
        font-size: 20px;
        align-items: center;
        margin-top: 10px;
    }

    .gif h1{
        font-size: 20px;
    }
    p{
        font-size: 13px;
    }
        .image-pic img{
        width: 180%;
        height: auto;
        border-radius: 50px;
        margin-top: 85px;
        margin-right: -150px;
        
       }

       .info{
        margin-top: -150px;
        margin-right: 50px;
       }

    .i-g-btn{
        margin-bottom: 40px;
        font-size: 8px; 
        width: 100px;
        height: 25px;
        padding: 0.5px;
       }

       .info p{
        
        font-size: 6px;
    }
    .info h2{
        font-size: 10px;
    }
    
   
}

@media screen and (max-width:480px){



    .container h2{
        font-size: 15px;
    }
    .container .heading h1{
        font-size: 25px;
       
        
    }

    
    .heading p{
        font-size: 8px;
    }
    .to{
       align-items: center;
       justify-content: center;
       display: flex;
    }
 
    .btns{
        width: 20px;
        height: 36px ;
        margin-bottom: 250px;
        font-size: 9px;
        margin-left: 28px;
        
    }
    .btnss{
        width: 150px;
        height: 1px ;
        margin-bottom: 80px;
        font-size: 6px;
        margin-left: 26px;
        margin-bottom: 250px;
    }
    .image h1{
        font-size: 15px;
        margin-left: 25px;
        margin-top: -120px;
    }
        

    .gif h1{
        font-size: 15px;
        margin-top: -120px;
    }
    p{
        font-size: 10px;
    }

    .container .image{
        margin-left: 8px;
        display: grid;
    }
    .container .gif{
        margin-left: 8px;
        display: grid;
    }


    .info{
        margin-top: -250px;
        margin-left: -30px;
       }

       .image-pic img{
        width: 220%;
        height: auto;
        border-radius: 50px;
        margin-right: -180px;
        

        
       }

    .i-g-btn{
        margin-bottom: 40px;
        font-size: 6px; 
        width: 80px;
        height: 20px;
        padding: 0.5px;
       }

       .info p{
        
        font-size: 6px;
    }
    .info h2{
        font-size: 10px;
    }
    .heading p {
        font-size: 10px;
    }
    
 
}

@media screen and (max-width:415px){
    .heading p {
        font-size: 10px;
    }
 
.btnss{
    margin-left: 18px ;
}

    .image h1{
        font-size: 14px;
        margin-left: 25px;
        margin-top: -120px;
    }
        

    .gif h1{
        font-size: 14px;
        margin-top: -120px;
        margin-left: 10px;
    }

    .image-pic img{
        width: 300%;
        height: auto;
        border-radius: 50px;
        margin-right: -180px;
        
       }

    .i-g-btn{
        margin-bottom: 40px;
        font-size: 6px; 
        width: 80px;
        height: 20px;
        padding: 0.5px;
       }

       .info p{
        
        font-size: 8px;
    }
    .info h2{
        font-size: 12px;
    }
}

/* @media screen and (max-width:415px){
    .image h1{
        font-size: 14px;
        margin-left: 25px;
        margin-top: -120px;
    }
        

    .gif h1{
        font-size: 14px;
        margin-top: -120px;
        margin-left: 10px;
    }

    .image-pic img{
        width: 300%;
        height: auto;
        border-radius: 50px;
        margin-right: -180px;
        
       }

    .i-g-btn{
        margin-bottom: 40px;
        font-size: 6px; 
        width: 80px;
        height: 20px;
        padding: 0.5px;
       }

       .info p{
        
        font-size: 8px;
    }
    .info h2{
        font-size: 12px;
    }
} */


@media screen and (max-width:389px){
    .heading p {
        font-size: 10px;
    }


    .btns{
        margin-left: 5px;
    }
    .image h1{
        font-size: 13px;
        margin-left: 25px;
        margin-top: -120px;
    }
        

    .gif h1{
        font-size: 13px;
        margin-top: -120px;
        margin-left: 10px;
    }

    .image-pic img{
        width: 500%;
        height: auto;
        border-radius: 50px;
        margin-right: -180px;
        
       }

    .i-g-btn{
        margin-bottom: 40px;
        font-size: 6px; 
        width: 80px;
        height: 20px;
        padding: 0.5px;
       }

       .info p{
        
        font-size:8px;
    }
    .info h2{
        font-size: 12px;
    }

}