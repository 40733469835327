* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* #14252c */
/* #10242c */

footer {
  width: 100%;
  position: relative;
  bottom: 0;
  background-color: #14252c;
  padding: 50px 0 20px; /* Adjusted padding for better spacing */
  font-size: 13px;
  line-height: 20px;
  color: white;
  
}

.row {
  width: 85%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.col {
  flex-basis: 20%; /* Adjusted flex basis for better distribution */
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
}

.col h3 {
  margin-bottom: 20px;
}

.col ul {
  list-style: none;
  
  
}

.col ul li {
  
  margin-bottom: 10px;
  font-size: 16px;
  cursor: pointer;
 
}

.col ul li :hover{
  color: royalblue;
  
}

.col ul li a {
  color: white;
  text-decoration: none;
}

.col ul li a:hover {
  text-decoration: underline;
}

.copyright {
  width: 85%;
  margin: auto;
  padding: 10px 0;
  text-align: center;
  border-top: 1px solid #444;
  margin-top: 20px;
  font-size: 12px; /* Slightly smaller font for copyright text */
}

/* Responsive media queries */

@media screen and (max-width:992px){
  .col ul li{
    font-size: 15px;
  }
}

@media screen and (max-width:768px){
  .col h3{
    font-size: 22px;
  }
  .col ul li{
    font-size: 11px;
  }

}

@media screen and (max-width:576px){
  .col h3{
    font-size: 15px;
  }
  .col ul li{
    font-size: 8px;
  }

}
@media screen and (max-width:480px){
  .col h3{
    font-size: 12px;
  }
  .col ul li{
    font-size: 6px;
  }
}




