* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    background: #0f1b21;
    color: white;
    overflow-x: hidden; /* Prevent horizontal scroll */
}
.gifsearch {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
    gap: 30px;
}

.headers {
    font-size: 70px;
    font-weight: 500;
    padding-bottom: 10px;
    color: white;
}
.headers span {
    color: #66FCF1;
    font-weight: 600;
}
.search-boxs {
    display: flex;
    width: 750px;
    height: 65px;
    justify-content: space-around;
    align-items: center;
    border-radius: 50px;
    background: #1F3540;
    
    
}

.search-inputs {
    flex: 1;
    height: 50px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 18px;
    color: white;
    padding-left: 35px;
}

.search-input::placeholder {
    color: #999;
}
.generate-bt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
   height: 65px;
   font-size: 20px;
   border-radius: 50px;
   background: #66FCF1;
   cursor: pointer;
   color: black;
   font-weight: bold;
}
.wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Adjust to fit */
    gap: 20px; /* Add gap between grid items */
    width: 100%; /* Ensure the wrapper takes full width */
    padding: 20px; /* Add padding to prevent overflow */
    justify-content: center; /* Center the grid items */
}

.containers {
    background-color: #2b304d;
    display: flex;
    padding: 1em; /* Adjusted padding */
    flex-direction: column;
    justify-content: space-between;
    margin: 0; /* Remove margin */
    border-radius: 0.5em;
    max-width: 100%; /* Ensure the container fits within its grid cell */
    box-sizing: border-box; /* Include padding in the width calculation */
}
.container img {
    width: 100%;
}

.at {
    position: relative;
    display: flex;
    padding-left: 250px;
}
.copy-link {
    background-color: #66FCF1;
    border: none;
    padding: 10px 22px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
}

/* Responsive media queries */

@media screen and (max-width: 1200px) {
    .wrapper {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adjust to fit */
    }
}

@media screen and (max-width: 992px) {
    .wrapper {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust to fit */
    }
        .search-boxs{
       
        width: 700px;
        height: 65px;

    
    }

    .generate-bt{
          width: 250px;

}
}

@media screen and (max-width: 768px) {
    .wrapper {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust to fit */
    }

    .search-boxs{
       
        width: 500px;
        height: 50px;

    
    }

    .generate-bt{
          width: 180px;
          height: 50px;

}
}

@media screen and (max-width: 576px) {
    .wrapper {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust to fit */
        justify-content: center; /* Center the grid items */
    }
    .containers {
        margin: auto; /* Center containers on small screens */
    }
    .container img {
        width: 100%;
    }

    .headers{
        font-size: 36px;
        font-weight: 500;
        padding-bottom: 10px;
        color: white;
    }

    .search-boxs{
       
        width: 350px;
        height: 50px;

    
    }

    .generate-bt{
          width: 120px;
          height: 40px;
          font-size: 15px;

}
.search-inputs {
 
    font-size: 12px;
    
   
}

}
